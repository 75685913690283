// stylelint-disable no-empty-source

@import "_main";

// Gulp looks for variables in the following files in the specified order.
// 1. In the NodeJS package X-Net Rocket SCSS _variables.scss
// 2. In the project.json in the project root
// 3. In the custom_variables.scss
@import "_custom_variables";

@import "_header";

//------------------------------------------------------------------------------
// LOGIN-AS
.fixed_alert_container {
  background-image: linear-gradient(to bottom, #FCF8E3 0%, #F8EFC0 100%);
  background-repeat: repeat-x;
  border-color: #F5E79E;
  font-size: rem(15);
  padding: rem(9);
}

.svg-inline {
  height: 1.25em;
  vertical-align: -0.225em;
  width: 1.25em;
}

.modal-error-xl {
  .modal-content {
    max-width: 90%;
  }
}

.circulation-table-wrapper {
  th,
  td {
    text-align: center;
    width: 20%;
  }
}

.table-border-radius {
  thead,
  thead tr {
    border-top-left-radius: var(--x-card-border-radius);
    border-top-right-radius: var(--x-card-border-radius);
    -moz-border-radius-topleft: var(--x-card-border-radius);
    -moz-border-radius-topright: var(--x-card-border-radius);
  }

  thead tr th:first-child {
    border-top-left-radius: var(--x-card-border-radius);
  }

  thead tr th:last-child {
    border-top-right-radius: var(--x-card-border-radius);
  }
}

#top-btn {
  display: none;
  position: fixed;
  bottom: 2rem;
  right: 3rem;
  cursor: pointer;
  padding: 1rem;
  z-index: 99;
}

@media only screen and (max-width: rem(400)) {
  #top-btn {
    bottom: 1rem;
    right: 1rem;
  }
}

summary {
  cursor: pointer;
}

a {
  fill: black;
}

.custom-date {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='#{$gray-800}' d='M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z'/></svg>"));
}

.circulations-tree {
  display: flex;
  flex-direction: column;
}

.filter-date {
  display: flex;
  align-items: center;
  margin-right: 1rem;

  input {
    min-width: inherit;
    padding: 0.25rem;
  }
}

// MOBILE
#projects_mobile_table {
  border: none;
  border-collapse: separate;
  border-spacing: rem(0) 2rem;

  tr {
    border: solid var(--x-table-border-color) 0.025rem;
    border-radius: 0.25rem;
  }

  td {
    border: none;
    border-top: solid var(--x-table-border-color) rem(2);
    border-bottom: solid var(--x-table-border-color) rem(2);
    vertical-align: center;
  }
}

.circulation-summary-mobile {
  padding: 1rem;
  border: solid var(--x-table-border-color) rem(2);
}

@media only screen and (max-width: rem(400)) {
  .circulation-summary-mobile {
    padding: 0.5rem;
  }
}

.circulation-wrapper-mobile {
  border-top-right-radius: rem(0);
  border-top-left-radius: rem(0);

  .mobile-card-header,
  .mobile-card-body {
    margin: 1rem;
  }

  @media only screen and (max-width: rem(400)) {
    .mobile-card-header,
    .mobile-card-body {
      margin: 0.5rem;
    }
  }
}

.nav-link {
  justify-content: right;
}

@media only screen and (max-width: rem(450)) {
  .navbar-nav .active {
    background-color: lightgrey;
  }
}

$eingehalten: #92d050;
$teilweise: #ff0;
$nicht_eingehalten: #f60;
$erfüllt: #090;
$nicht_erfüllt: #f00;
$offen: #00b0f0;
$nicht_relevant: #d0cece;

.bg-complied {
  background-color: $eingehalten;
}

.bg-partial {
  background-color: $teilweise;
}

.bg-not-complied {
  background-color: $nicht_eingehalten;
}

.bg-fulfilled {
  background-color: $erfüllt;
}

.bg-not-fulfilled {
  background-color: $nicht_erfüllt;
}

.bg-open {
  background-color: $offen;
}

.bg-not-relevant {
  background-color: $nicht_relevant;
}

.checkpoint-header {
  @extend .card-header;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.tag {
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: rem(400)) {
  .tag {
    padding: 0.5rem;
  }
}

.border-radius-status {
  border-radius: 1rem;
}

.no_marker {
  list-style: none;

  summary::-webkit-details-marker,
  summary::marker {
    display: none;
    list-style: none;
    content: "";
  }
}

// stylelint-disable declaration-no-important
.report-mark {
  background-color: #eadebd !important;
}

// stylelint-enable declaration-no-important
.mobile-container {
  padding-top: 1rem;
  padding-left: 1rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: rem(400)) {
  .mobile-container {
    padding-left: rem(5);
  }
}

.leaflet-container { /* all maps */
  width: rem(600);
  height: rem(400);
}

@media screen and (max-width: rem(600)) {
  .leaflet-container { /* all maps */
    width: rem(500);
  }
}

@media screen and (max-width: rem(500)) {
  .leaflet-container { /* all maps */
    width: rem(400);
  }

  body {
    background-color: rgba(255, 255, 255, 0.6);
  }
}

@media screen and (max-width: rem(400)) {
  .leaflet-container { /* all maps */
    width: rem(350);
  }
}

@media screen and (max-width: rem(350)) {
  .leaflet-container { /* all maps */
    width: rem(320);
  }
}

@media screen and (max-width: rem(300)) {
  .leaflet-container { /* all maps */
    width: rem(280);
  }
}

.img-preview {
  max-width: rem(500);
  max-height: rem(300);
}

.img-mobile-preview {
  max-width: 15rem;
  max-height: 15rem;
}

.mw-100 {
  max-width: 100%;
}

.shown_circulation {
  border: solid rem(3) lightgray;
}

#projects_mobile_table {
  tr {
    @extend .bg-gray-200;
  }
}

.min-w-75rem {
  min-width: 75rem;
}

/* ------------------------------------------------------------------------------
  LOADER
*/

@keyframes btn-loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-loader {
  // stylelint-disable declaration-no-important
  color: transparent !important;
  fill: transparent !important;
  // stylelint-enable declaration-no-important
  cursor: default;
  pointer-events: none;
  position: relative;

  &::after {
    animation: btn-loader 300ms linear infinite;
    border: rem(2) solid transparentize($white, 0.5);
    border-radius: 100%;
    border-top-color: $white;
    content: "";
    display: block;
    height: rem(24);
    left: 50%;
    margin-left: rem(-12);
    margin-top: rem(-12);
    position: absolute;
    top: 50%;
    transition: opacity;
    width: rem(24);
  }
}

#id_circulationform-fulfillment_status {
  option[value="1"] {
    background-color: $eingehalten;
    font-weight: bolder;
    color: white;
  }

  option[value="2"] {
    background-color: $teilweise;
    font-weight: bolder;
  }

  option[value="3"] {
    background-color: $nicht_eingehalten;
    font-weight: bolder;
    color: white;
  }

  option[value="4"] {
    background-color: $erfüllt;
    font-weight: bolder;
    color: white;
  }

  option[value="5"] {
    background-color: $nicht_erfüllt;
    font-weight: bolder;
    color: white;
  }

  option[value="6"] {
    background-color: $offen;
    font-weight: bolder;
    color: white;
  }

  option[value="7"] {
    background-color: $nicht_relevant;
    font-weight: bolder;
  }
}
